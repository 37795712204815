@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
$container-padding-x: 2.2rem;
@import '~bootstrap/scss/bootstrap';

$blue: #164194;
$darkblue: #22273f;
$green: #8ce67c;
$pink: #e67c7c;
$white: #f8f8f8;
$font-text: 'Manrope', sans-serif;
$font-title: 'Oswald', sans-serif;

app-root.homepage section:nth-child(1) {
   padding-bottom: 0px;
}

app-root.homepage section:nth-child(2) {
   display: none;
}

app-root.homepage .blue-nav {
   display: none;
}

.btn-primary {
   background-color: $blue;
   border-color: $blue;
   transition: 0.3s;
}

.btn-primary.active {
   background-color: #365be1;
   border-color: #365be1;
}

.btn-primary:hover {
   transition: 0.3s;
}

.container-fluid {
   max-width: 1350px;
}

.container-wrapper {
   min-height: 100%;
}

html {
   font-size: 62.5%;
   -ms-text-size-adjust: 100%;
}

h1 {
   font-size: clamp(3rem, 3.3vw, 5rem);
}

h2 {
   font-size: clamp(3rem, 3.3vw, 5rem);
}

h3 {
   font-size: clamp(1.8rem, 1.2vw, 2rem);
}

h4 {
   font-size: clamp(1.5rem, 1.3vw, 2rem);
}

h5 {
   font-size: 1.6rem;
   font-weight: bold;
   font-family: $font-text;
}

a {
   font-size: 1.4rem;
   text-decoration: none;
}

p {
   font-family: $font-text;
   font-size: 1.5rem;
}

li {
   list-style: none;
}

h1,
h2,
h3,
h4,
a {
   font-family: 'Montserrat';
}

h1,
h2,
h3,
h4 {
   // text-transform: uppercase;
   font-weight: bold;
}

.page-item.active .page-link {
   background-color: $blue;
   border-color: $blue;
}

.page-link {
   color: $blue;
}

.preview {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   overflow: hidden;
   line-height: 2;
}

// ----- HOME-BUTTONS -----
.icon-div {
   right: 1%;
   position: fixed;
   width: auto;
   height: 143px;
   margin-left: 0;
   top: 70%;

   button {
      background-color: white;
      padding: 12px;
      line-height: 16px;
      box-shadow: -1px 1px 0 #919191;
      border: none;
      border-radius: 0;
      text-align: center;
      justify-content: center;
      display: flex;
      font-size: 2px;
      margin-bottom: 6px;
      transition: 0.3s;
      color: $blue;
   }

   button.active,
   button:hover {
      background-color: $blue;
      fill: white;
      color: white;
      box-shadow: none;
      transition: 0.3s;
   }
}

@media (max-width: 992px) {
   .icon-div {
      top: 60%;
      right: 3%;
   }
}

.homepage {
   div.blue:nth-child(1) > div:nth-child(1) {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
   }

   .blue {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue;
      border-top: 5px solid rgb(238, 32, 0);
      .ng-select {
         font-size: clamp(11px, 4vw, 12px);
      }

      .btn-check:checked + .btn-secondary,
      .btn-check:active + .btn-secondary,
      .btn-secondary:active,
      .btn-secondary.active,
      .show > .btn-secondary.dropdown-toggle {
         color: #000;
         background-color: #ffcd39;
         border-color: #51585e;
      }

      .ng-select .ng-clear-wrapper {
         display: none;
      }

      .btn-div-right {
         margin-right: 15px;
         padding-right: 0.5rem;
         padding-left: 0.5rem;
      }
   }

   @media (max-width: 992px) {
      .blue {
         .ng-select {
            width: 100%;
         }

         .btn-div-right {
            padding: 0;
            margin: 0 !important;
         }
      }
   }

   @media (max-width: 768px) {
      .btn-div-left {
         width: 100%;
      }
   }
}

// ----- HOME-MENU -----
.slider {
   position: fixed;
   left: 50%;
   transform: translateX(-50%);
   width: 95%;
   top: calc(100% - 108px);
   height: 90%;
   display: flex;
   z-index: 11111;
   transition: all 0.5s ease-out;
   overflow-y: hidden;
   background-color: white;
   z-index: 1;
}

span {
   font-weight: bold;
}

.detail-div {
   line-height: 0;
}

.panel-legend {
   width: auto;
   // max-width: 450px;
}

.slider.open {
   top: 170px;
   transition: all 0.5s ease-out;
}

.icon-bar {
   position: absolute;
   height: 5px;
   width: 20%;
   left: 50%;
   transform: translateX(-50%);
   top: 17px;
   display: block;
   background-color: rgba(0, 0, 0, 0.226);
   cursor: pointer;
   border-radius: 10px;
}

.graph-1,
.graph-2 {
   height: 230px;
   width: 48%;
   border: 1px solid;
}

.bottom-div {
   overflow-y: auto;
   height: 52%;
}

@media (min-width: 992px) {
   .slider {
      position: fixed;
      bottom: 0 !important;
      left: 1%;
      width: 400px;
      height: 450px;
      margin-left: 0;
      top: 251px;
      transform: translateY(-20%);
   }
}

@media (max-width: 992px) {
   .bottom-div {
      height: 62%;
   }

   .panel-legend {
      max-width: 96%;
   }
}

.news-cards-home {
   .link-div {
      text-align: right;
      margin: 0 auto;
   }

   h4 {
      color: #2282ff;
   }

   .content {
      img {
         width: 100%;
      }
   }

   a {
      color: #2282ff;
      text-transform: uppercase;
      font-weight: bold;
   }

   a:hover {
      text-decoration: none;
   }
}

.carousel-comp {
   .arrow-prev,
   .arrow-next {
      svg {
         transform: translateY(-353%);
      }
   }

   .pagination {
      width: auto;
   }

   img {
      width: 100%;
   }

   a {
      color: $blue;
      text-transform: uppercase;
      font-weight: bold;
   }
}

markdown.publications_md {
   h3 {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
   }

   p {
      margin-bottom: 2rem;
   }
}

a {
   text-decoration: underline;
   color: $blue;
}

// ----- FOOTER -----
.footer {
   .border-div {
      border-top: 3px solid;
      border-bottom: 2.5px solid;
      border-image: linear-gradient(to right, #164194 33%, #ee2000 33%, #ee2000 66%, green 66%) 2;
      position: relative;
      bottom: 0;
      width: 100%;
      height: auto;

      // .footer-div {
      //    padding: 30px;
      // }
   }

   .link_div {
      width: 80%;
      padding: 30px 30px 50px 30px;

      a {
         text-decoration: none;
         font-size: 1.4rem;
      }
   }
}

// ----- PAGE VIEW -----
.page-view {
   .banner {
      background-size: cover;
      position: relative;
      max-height: 30vh;
      z-index: -1;

      .cont {
         height: auto;
         padding-bottom: 32%;
      }
   }

   .content {
      div {
         margin-top: 4rem;
      }
   }

   .download-section {
      width: 100%;
      height: fit-content;
      max-width: 400px;
      margin-left: 150px;

      div {
         margin-right: 20px;
      }
   }

   .download-box {
      background-color: lightgray;

      p {
         margin: 0;
         font-size: clamp(1.2rem, 1vw, 1.8rem);
      }

      a {
         svg {
            fill: black;
         }

         svg:hover {
            fill: $blue;
         }
      }
   }

   @media (max-width: 992px) {
      .download-section {
         max-width: 100%;
         margin: 0;

         div {
            margin-right: 0;
         }
      }
   }
}

// ----- NAV -----
.navbar-component {
   background-color: white !important;

   .blue {
      height: 4.6rem;
      background-color: $blue;
      border-top: 5px solid rgb(238, 32, 0);
   }

   nav {
      min-height: 90px;
   }

   .navbar-collapse {
      flex-grow: 0 !important;
   }

   .navbar-brand,
   img {
      z-index: 999;
      max-height: 90px;
   }

   .navbar-toggler-icon {
      width: 3.5em;
      height: 3.5em;
   }

   .navbar-toggler {
      z-index: 999;
   }

   ul {
      margin-top: 0;
      margin-bottom: 0;
   }

   li {
      display: flex;
      list-style: none;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 2.5;
   }

   .nav-link:hover {
      color: $blue;
   }

   a {
      color: black;
      font-size: clamp(0.8rem, 0.8vw, 1.3rem);
      text-decoration: none;
      font-family: 'Montserrat';
   }

   a:hover {
      text-decoration: none;
   }

   .overlay {
      width: 100%;
      position: fixed;
      z-index: 5;
      left: 0;
      top: 0;
      background-color: white;
      overflow-x: hidden;
      transition: 0.5s;
      overflow-y: hidden;
   }

   .overlay-content {
      position: relative;
      width: 100%;
      text-align: center;
      margin-top: 13rem;
      padding-bottom: 60%;
   }

   .overlay a {
      text-decoration: none;
      font-size: 36px;
      color: rgb(22, 65, 148) !important;
      display: block;
      transition: 0.3s;
   }

   ul {
      padding-left: 15px;
      padding-right: 15px;

      p {
         bottom: 0;
         width: 60%;
         margin-top: 80%;
         color: white;
         padding-left: 15px;
         padding-right: 15px;
      }
   }

   hr {
      width: 1.3rem;
   }

   .overlay .closebtn {
      position: absolute;
      top: 0px;
      right: 25px;
      font-size: 60px;
   }

   .visible {
      height: 100%;
   }

   .hidden {
      height: 0;
   }

   .collapsed {
      width: 45px;
      height: 20px;
      display: block;
      position: relative;
      z-index: 999;

      &:before,
      &:after {
         content: '';
         display: block;
         width: 100%;
         height: 4px;
         border-radius: 0;
         background: rgb(22, 65, 148);
         position: absolute;
         transition: all 0.2s ease-in;
         transform: scaleX(-1);

         transform: rotate(45deg);
         top: 8px;
      }
      &:after {
         top: auto;
         bottom: 8px;
         width: 100%;
         transform: rotate(-45deg);
      }
      &:before {
         transform: scaleX(-1) rotate(-45deg);
      }

      .icon-bar {
         position: absolute;
         height: 0;
         width: 100%;
         top: 17px;
         display: block;
         transition: all 0.2s ease-in;
      }
   }

   .not-collapsed {
      width: 45px;
      height: 20px;
      display: block;
      position: relative;
      z-index: 999;

      &:before,
      &:after {
         content: '';
         display: block;
         width: 100%;
         height: 4px;
         border-radius: 0;
         background: $blue;
         position: absolute;
         top: 0;
         transition: all 0.2s ease-in;
         transform: scaleX(-1);
      }
      &:after {
         top: auto;
         bottom: 0;
         right: 0;
      }

      .icon-bar {
         position: absolute;
         height: 0;
         width: 100%;
         top: 17px;
         display: block;
         transition: all 0.2s ease-in;
      }
   }

   @media (max-width: 991.98px) {
      .navbar-nav {
         margin-left: initial !important;
      }

      .overlay {
         ul {
            align-items: left;
            text-align: left;
         }
      }
   }

   @media only screen and (max-width: 576px) {
      .navbar-toggler-icon {
         width: 2.5em;
         height: 2.5em;
      }

      .logo-mobile {
         height: 5.5rem;
         width: 20.2rem;
      }
      .overlay {
         ul {
            align-items: left;
            text-align: left;
         }
      }

      .overlay a {
         font-size: 2.5rem;
      }
      .overlay .closebtn {
         font-size: 40px;
      }
   }
}

.news-div {
   color: black;

   div {
      position: relative;
      object-fit: contain;
      padding-bottom: 52.63%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
   }

   h3,
   div {
      cursor: pointer;
   }
}

// ----- NEWS LIST -----
.news-list {
   img {
      width: 100%;
      height: 40%;
   }

   .news-div {
      margin-bottom: 60px;
      width: 100%;

      h5 {
         font-size: 5rem;
         font-family: $font-text;
      }

      .date {
         color: $blue;
      }

      a {
         text-transform: uppercase;
         font-weight: bold;
      }
   }

   .custom-select {
      text-transform: lowercase;
   }

   @media only screen and (max-width: 576px) {
      .news-div {
         h4 {
            font-size: 3rem;
         }

         // h5 {
         //    font-size: 3.2rem;
         // }
      }
   }
}

// ----- CONTACTS -----
.contacts {
   #map,
   .loading {
      height: 40rem;
      z-index: 1;
   }

   h1 {
      color: $darkblue;
   }
}

// ----- NEWS VIEW -----
.news-view {
   .news_view_container {
      padding-bottom: 6%;
   }
   .news_card {
      padding-right: 30px;

      div {
         a {
            text-align: right;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.5rem;
         }
      }
   }

   .content {
      div {
         a {
            text-transform: uppercase;
            font-weight: bold;
         }
      }

      .date {
         color: $blue;
         margin: 0;
      }
   }

   h5 {
      font-size: 3.5rem;
      font-family: $font-text;
   }

   h4 {
      font-size: 1.5rem;
   }

   .outer_content {
      h2 {
         text-transform: uppercase;
      }
   }

   @media only screen and (max-width: 768px) {
      .news_card {
         padding-right: 0;
      }
   }

   @media only screen and (max-width: 576px) {
      .news_card {
         padding-right: 0;

         div {
            a {
               font-size: 1.4rem;
            }
         }
      }

      // h5 {
      //    font-size: 3.2rem;
      // }
   }
}
